import React from "react"
import { Link, graphql } from "gatsby"
import  Layout from "../components/layout"
import SEO from "../components/seo"

import { BlogProps } from "../components/blog/blog-types"


const BlogPost = ({ data, ...props }: BlogProps) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const { previous, next } = data

  return (
    <>
    <Layout>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
        style={{width: "50%", minWidth: "350px", margin: "0 auto"}}
      >
        <header>
          <h1 itemProp="headline">{post.frontmatter.title}</h1>
          <p>By: <b>{post.frontmatter.author}</b> | {post.frontmatter.date}</p>
        </header>
        <section
          dangerouslySetInnerHTML={{ __html: post.html }}
          itemProp="articleBody"
        />
        <hr />
        <footer>
          <nav className="blog-post-nav">
          <ul
            style={{
              display: `flex`,
              flexWrap: `wrap`,
              justifyContent: `space-between`,
              listStyle: `none`,
              padding: 0,
            }}
            >
              <li>
                {previous && (
                  <Link to={previous.fields.slug} rel="prev">
                    ← {previous.frontmatter.title}
                  </Link>
                )}
              </li>
              <li>
                {next && (
                  <Link to={next.fields.slug} rel="next">
                    {next.frontmatter.title} →
                  </Link>
                )}
              </li>
            </ul>
          </nav>
        </footer>
      </article>
    </Layout>
    
    </>
  )
}

export default BlogPost

export const postQuery = graphql`
    query BlogPostBySlug(
        $id: String!
        $previousPostId: String,
        $nextPostId: String
    ) {
        site {
            siteMetadata {
                title
            }
        }
        markdownRemark(id: { eq: $id }) {
          id
          excerpt(pruneLength: 160)
          html
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            description
            author
            category
            tags
          }
        }
        previous: markdownRemark(id: { eq: $previousPostId }) {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
        next: markdownRemark(id: { eq: $nextPostId }) {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
    }`